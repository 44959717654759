import { useState } from "react";
import { Helmet } from 'react-helmet-async';

const Commands = () => {
    
    // Filters List
    // all, confessions, moderation, fun, emotes, utility
    const [filters] = useState([
        {filterName: 'All', filterID:"all", id: 1},
        {filterName: 'Setup', filterID:"Setup", id: 2},
        {filterName: 'Moderation', filterID:"moderation", id: 3},
        {filterName: 'Fun', filterID:"fun", id: 4},
        {filterName: 'Public', filterID:"public", id: 5},
        {filterName: 'Utility', filterID:"utility", id: 6},
        {filterName: 'Premium', filterID:"Premium", id: 7},
        {filterName: 'Information', filterID:"Information", id: 8}

    ])

    // Commands List
    const [commands] = useState([
        {commandName: '/help', commandDesc:"A full list of HammerBot's commands", commandUsage:"/help", filter:"Information", id: 1},
        {commandName: '/verify', commandDesc:"setup the verification system, you can also mention a user by using {user}", commandUsage:"/verify [role], [channel], [content]", filter:"Setup", id: 2},
        {commandName: '/set-welcomer', commandDesc:"Setup A Server Welcomer", commandUsage:"/welcomer [channel] [content],[embed]", filter:"Setup", id: 3},
        {commandName: '/disable-welcomer', commandDesc:"Disable the welcomer in a guild", commandUsage:"/disable-welcomer", filter:"Setup", id: 4},
        {commandName: '/logs setup', commandDesc:"Setup the Moderation Logs", commandUsage:"/logs setup [channel]", filter:"Setup", id: 5},
        {commandName: '/disable-logs', commandDesc:"Disable the Moderation Logs ", commandUsage:"/disable-logs", filter:"Setup", id: 6},
        {commandName: '/automod flagged-words ', commandDesc:"Setup the automod flagged-words ", commandUsage:"/automod flagged-words  ", filter:"Premium", id: 7, premium: "This command require Premium tire 1"},
        {commandName: '/automod mention-spam', commandDesc:"set the automod Mention spam ", commandUsage:"/automod mention-spam [number]", filter:"Premium", id: 8, premium: "This command require Premium tire 1"},
        {commandName: '/checklogs', commandDesc:"Checks to see if the server logs confessions", commandUsage:"/checklogs", filter:"confessions", id: 9},
        {commandName: '/warning', commandDesc:"send a user a warning before a kick or a banned", commandUsage:"/warning [@person]", filter:"moderation", id: 10},
        {commandName: '/warnings', commandDesc:"Check how mean warnings a user has", commandUsage:"/warnings [@person]", filter:"moderation", id: 11},
        {commandName: '/define', commandDesc:"Gets the definition of a word", commandUsage:"/define [word]", filter:"fun", id: 12},
        {commandName: '/binary', commandDesc:"Encodes/Decodes binary text", commandUsage:"/binary [encode/decode] [text]", filter:"fun", id: 13},
        {commandName: '/tools', commandDesc:"See all the tools", commandUsage:"/tools ", filter:"public", id: 14, premium: "N/A"},
        {commandName: '/hug', commandDesc:"Sends a GIF kissing emote", commandUsage:"/hug [@person]", filter:"emotes", id: 15},
        {commandName: '/cuddle', commandDesc:"Sends a GIF kissing emote", commandUsage:"/cuddle [@person]", filter:"emotes", id: 16},
        {commandName: '/slap', commandDesc:"Sends a GIF slapping emote", commandUsage:"/slap [@person]", filter:"emotes", id: 17},
        {commandName: '/kill', commandDesc:"Sends a GIF killing emote", commandUsage:"/kill [@person]", filter:"emotes", id: 18},
        {commandName: '/timeout', commandDesc:"Timeout a user", commandUsage:"/timeout [@person], [duration], [format]", filter:"moderation", id: 19},
        {commandName: '/unlock', commandDesc:"Disable the lockdown", commandUsage:"/unlock [channel]", filter:"moderation", id: 20},
        {commandName: '/lockdown', commandDesc:"lockdown a channel", commandUsage:"/lockdown [channel]", filter:"moderation", id: 21},
        {commandName: '/nick', commandDesc:"Change a users nickname", commandUsage:"/nick [@person]", filter:"moderation", id: 22},
        {commandName: '/slowmode', commandDesc:"enable Slowmode on a channel", commandUsage:"/slowmode [channel], [duration], [format]", filter:"moderation", id: 23},
        {commandName: '/role', commandDesc:"Give a user a role", commandUsage:"/role [user], [role]", filter:"moderation", id: 24},
        {commandName: '/clear', commandDesc:"Clear amount of messages in a channel", commandUsage:"/clear [number]", filter:"moderation", id: 25},
        {commandName: '/mute', commandDesc:"Mute a mention user", commandUsage:"/mute [@person]", filter:"moderation", id: 26},
        {commandName: '/ban', commandDesc:"Bans the mentioned person", commandUsage:"/ban [@person]", filter:"moderation", id: 27},
        {commandName: '/kick', commandDesc:"Kicks the mentioned person", commandUsage:"/kick [@person]", filter:"moderation", id: 28},
        {commandName: '/animal', commandDesc:"Sends a random animal image", commandUsage:"/animal [animal_category]", filter:"fun", id: 29},
        {commandName: '/joke', commandDesc:"Sends a random (corny) joke", commandUsage:"/joke", filter:"fun", id: 30},
        {commandName: '/dice', commandDesc:"Rolls a 6 sided die", commandUsage:"/dice", filter:"fun", id: 31},
        {commandName: '/coinflip', commandDesc:"Flips a coin", commandUsage:"/coinflip", filter:"fun", id: 32},
        {commandName: '/userinfo', commandDesc:"Shows info about a user or server", commandUsage:"/info [user/server]", filter:"Information", id: 33},
        {commandName: '/ping', commandDesc:"Shows HammerBot's ping", commandUsage:"/ping", filter:"utility", id: 34},
        {commandName: '/uptime', commandDesc:"Shows HammerBot's uptime", commandUsage:"/uptime", filter:"Information", id: 35},
        {commandName: '/stats', commandDesc:"Shows HammerBot's stats", commandUsage:"/stats", filter:"utility", id: 36},
        {commandName: '/support', commandDesc:"Sends the invite for HammerBot's support server", commandUsage:"/support", filter:"utility", id: 37},
        {commandName: '/delete_data', commandDesc:"Deletes all stored data for the current server", commandUsage:"/delete_data", filter:"utility", id: 38},
        {commandName: '/create-backup', commandDesc:"Create a server backup, backup message,roles,channels,etc", commandUsage:"/create-backup", filter:"Premium", id: 39, premium: "This command require Premium tire 1"},
        {commandName: '/load', commandDesc:"Load Server Bacup", commandUsage:"/load [backup_id]", filter:"Premium", id: 40, premium: "This command require Premium tire 1"},
        {commandName: '/setup-anti-raid', commandDesc:"Setup Anti-Raid Protection", commandUsage:"/setup-anti-raid", filter:"Premium", id: 41, premium: "This command require Premium tire 1"},
        {commandName: '/Reaction Roles', commandDesc:"Setup Reaction Roles", commandUsage:"/Reaction Roles [Role], [message]", filter:"Premium", id: 42, premium: "This command require Premium tire 1"},

    ])

    //Button Handlers
    const [selectedCommand, setSelectedCommand] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState('all');

    const handleClickCommand = (commandId) => {
        setSelectedCommand(commandId === selectedCommand ? null : commandId);
    };
    const handleClickFilter = (filter) => {
        setSelectedFilter(filter === selectedFilter ? 'all' : filter);
    };

    return ( 
        <div className="CommandsContent">
            <Helmet>
                <title>Commands • HammerBot</title>
                <meta property="og:title" content="Commands • HammerBot" />
                <meta name="description" content="The commands list for HammerBot." />
                <meta property="og:description" content="The commands list for HammerBot." />
                <meta property="og:url" content="HammerBotbot.xyz/commands" />
            </Helmet>
            <div className="CommandsLayoutContainer">
                <div className="CommandBoxContainer">
                <div className="CommandAltAnimationHandler"> 
                    <div className="CommandsSectionTitle">Commands</div>
                    <div className="CommandsFilterContainer">
                    {filters.map((filter) => (
                        <div className="FilterButton" id={`${filter.filterID === selectedFilter ? "selected" : "" }`} onClick={() => handleClickFilter(filter.filterID)} key={filter.id}>
                            <div className="FilterTitle">{filter.filterName}</div>
                        </div>
                    ))}
                </div>
                </div>
                <div className="CommandListContainer">
                    {commands.map((command, index) => (
                        <div className="CommandBubbleAnimationHandler" style={{ animationDelay: `${25 * index}ms` }} key={command.id}>
                            <div className={`${command.id === selectedCommand ? "CommandBubbleExpanded" : "CommandBubble" }`} id={`${command.filter === selectedFilter || 'all' === selectedFilter ? "" : "inactive" }`} key={command.id} onClick={() => handleClickCommand(command.id)}>
                                <div className="CommandBubbleInsideContainer">
                                    <div className="CommandBubbleNonExpandedContainer">
                                        <div className="CommandBubbleTitleContainer">
                                            <div className="CommandBubbleTitle">{command.commandName}</div>
                                            <div className="CommandBubbleExpandArrow" id={`menuButton_${command.id === selectedCommand ? 'active' : 'inactive'}`}>
                                                <svg width="30" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="dropdownIcon" id={`dropdownIcon_${command.id === selectedCommand ? 'active' : 'inactive'}`}><path d="M7 14.5l5-5 5 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            </div>
                                        </div>
                                        <div className="CommandBubbleDesc">{command.commandDesc}</div>
                                    </div>
                                    <div className="CommandBubbleExpandedContainer" id={`${command.id === selectedCommand ? "" : "inactive" }`} >
                                        <div className="CommandBubbleField">
                                            <div className="CommandBubbleFieldTitle">Usage</div>
                                            <div className="CommandBubbleFieldSubTitleContainer" id="usage">
                                                <div className="CommandBubbleFieldSubTitle">
                                                    {command.commandUsage} 
                                                </div>
                                                </div>
                                                <div className="CommandBubbleFieldTitle">Premium</div>
                                            <div className="CommandBubbleFieldSubTitleContainer" id="premium">
                                                <div className="CommandBubbleFieldSubTitle">
                                                    {command.premium} 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="CommandBubbleField">
                                            <div className="CommandBubbleFieldTitle">Category</div>
                                            <div className="CommandBubbleFieldSubTitle">{command.filter}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    ))}
                </div>    
            </div>
            </div>
        </div>
     );
}
 
export default Commands;