import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    return ( 
        <div className="HomeContent">
            <Helmet>
                <title>HammerBot</title>
                <meta property="og:title" content="HammerBot" />
                <meta name="description" content="The best free Discord confession bot." />
                <meta property="og:description" content="The best free Discord confession bot." />
                <meta property="og:url" content="HammerBotbot.xyz" />
            </Helmet>
            <div className="LayoutContainer">
                <div className="InfoBubble">
                    <div className="InfoColLeft">
                        <div className="InfoContent">
                                <div className="AltBoldText">HammerBot</div>
                                <div className="MainText">Make A Professional Discord Server!</div>
                                <div className="AltText">A very customizable multipurpose bot for welcome image, In-depth logs, Social commands, Moderation and many more ...</div>
                                <div className="infoButtonContainer">
                                    <Link to="/invite" className='GenericLinkDeco'>
                                        <div className="infoButton" id="InfoMainButton">
                                            Invite me
                                        </div>
                                    </Link>
                                    <Link to="https://patreon.com/HammerBot" className='GenericLinkDeco'>
                                        <div className="infoButton" id="InfoMainButton">
                                            Premium
                                        </div>
                                        </Link>
                                    <Link to="/commands" className='GenericLinkDeco'>
                                        <div className="infoButton" id="InfoAltButton">
                                            Commands
                                        </div>
                                    </Link>
                                </div>
                        </div>
                    </div>
                    <div className="InfoColRight">
                        <div className="InfoImg">
                            <img rel="prefetch" src="./img/Logos/HammerBot.png" alt="HammerBotLogo" width="150px" height="150px"/>
                        </div>
                    </div>
                </div>
                <div className='InfoDividerHR'><hr /></div>
                <div className='FeatureTitleContainer'>
                    <div className='FeatureTitleText'>Features:</div>
                </div>
                <div className='FeatureBubbleContainer'>
                    <div className="FeatureBubble" id='first'>
                        <div className="FeatureColLeft">
                            <div className='FTextContainer'>
                                <div className='FTitle' id='LongBoi'>Verification</div>
                                <div className='FSubTitle'>You can easily set CAPTCHA, Click-To-Pass, Pass-Phrase  If you have set it as CAPTCHA, users will get CAPTCHA message to verify themselves and Hammer will want them to enter the code they got or head over to the website in order to be HammerBot.</div>
                            </div>
                        </div>
                        <div className="FeatureColRight">
                            <div className='FPic'>
                                <img rel="prefetch" src="./img/FeatureScreenshots/Cscreenshot.png" alt="HammerBot Confession Screenshot" width="270px" height="130px"/>  
                            </div>
                        </div>
                    </div>
                    <div className="FeatureBubble" id='second'>
                        <div className="FeatureColLeft">
                            <div className='FPic' id='CL'>
                                <img rel="prefetch" src="./img/FeatureScreenshots/CLscreenshot.png" alt="HammerBot Confession Logging Screenshot" width="280px" height="168px"/>  
                            </div>
                        </div>
                        <div className="FeatureColRight">
                            <div className='FTextContainer' id='CL'>
                                <div className='FTitle'> Logging</div>
                                <div className='FSubTitle'>Log all your Moderation </div>
                            </div>
                        </div>
                    </div>
                    <div className="FeatureBubble" id='third'>
                        <div className="FeatureColLeft">
                            <div className='FTextContainer' id='GE'>
                                <div className='FTitle'>Welcomer</div>
                                <div className='FSubTitle'>'Welcome new Members to your server mention a user </div>
                            </div>
                        </div>
                        <div className="FeatureColRight">
                            <div className='FPic' id='GE'>
                                <img rel="prefetch" src="./img/FeatureScreenshots/GEscreenshot.png" alt="HammerBot GIF Emote Screenshot" width="280px" height="210px"/>  
                            </div>
                        </div>
                    </div>
                </div>
                <div className='EndOfPageSpacer' />
            </div>
        </div>
     );
}
 
export default Home;